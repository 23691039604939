import BasePreloader from '../../BasePreloader';
import logo808 from './assets/logo_808.svg';
import onlyplayLogo808 from './assets/onlyplaylogo_808.svg';
import './preloader808.css'

export default class Preloader808 extends BasePreloader {
  createMarkup() {
    document.body.insertAdjacentHTML('beforeend',
      `<div id="preloader" class="preloader branding_808">
        <div class="preloaderContainer">
          <object data="${logo808}" class="preloaderLogoBase preloaderLogo branding_logo_808"></object>
          <div id="percents">0%</div>
          <div class="progressContainer">
            <div class="progressBg">
              <div id="progressBar" class="progress"></div>
            </div>
          </div>
          <span class="branding_powered_text_808">POWERED BY</span>
          <object data="${onlyplayLogo808}" class="preloaderLogoBase preloaderLogo branding_onlyplay_logo_808"></object>
        </div>
      </div>`);

    this.preloaderElement = document.getElementById('preloader');
    this.progressBar = document.getElementById('progressBar');
  }
}
