import eBrandId from './eBrandId';
import Preloader808 from './808/preloader808';
import Preloader818 from './818/preloader818';

export const allPreloaders = {
    [eBrandId.EBI_808]: Preloader808,
    [eBrandId.EBI_818]: Preloader818
}

  export default {
    [eBrandId.EBI_808]: allPreloaders[eBrandId.EBI_808]
}

